// @ts-nocheck
import React from 'react';
import Link from '@/components/elements/Link';
import { ArchiveListProps } from './ArchiveList.types';
import * as styles from './ArchiveList.module.scss';

export default function ArchiveListSection({ archive }: ArchiveListProps) {
  function groupByYear(objects: any) {
    const grouped: any = {};

    objects.forEach((obj: any) => {
      const date = new Date(obj.node.created_at);

      const year = date.getFullYear();

      if (!grouped[year]) {
        grouped[year] = [];
      }

      grouped[year].push(obj.node);
    });

    return grouped;
  }
  const groupedArchive = groupByYear(archive);

  return (
    <div>
      {Object.entries(groupedArchive).map(yearGroup => (
        <section key={yearGroup[0]}>
          <h3 className={styles.heading}>{yearGroup[0]}</h3>
          <ul className={styles.list}>
            {yearGroup[1]?.map(link => (
              <li key={link.full_slug} className={styles.item}>
                <Link href={`/${link.full_slug}`} text={link.name} />
              </li>
            ))}
          </ul>
        </section>
      ))}
    </div>
  );
}
